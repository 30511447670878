// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-bon-podarunkowy-index-tsx": () => import("./../../../src/pages/bon-podarunkowy/index.tsx" /* webpackChunkName: "component---src-pages-bon-podarunkowy-index-tsx" */),
  "component---src-pages-bydgoszcz-dermatoskopowe-badanie-znamion-wideodermatoskopia-tsx": () => import("./../../../src/pages/bydgoszcz/dermatoskopowe-badanie-znamion-wideodermatoskopia.tsx" /* webpackChunkName: "component---src-pages-bydgoszcz-dermatoskopowe-badanie-znamion-wideodermatoskopia-tsx" */),
  "component---src-pages-cennik-index-tsx": () => import("./../../../src/pages/cennik/index.tsx" /* webpackChunkName: "component---src-pages-cennik-index-tsx" */),
  "component---src-pages-gdansk-dermatoskopowe-badanie-znamion-wideodermatoskopia-tsx": () => import("./../../../src/pages/gdansk/dermatoskopowe-badanie-znamion-wideodermatoskopia.tsx" /* webpackChunkName: "component---src-pages-gdansk-dermatoskopowe-badanie-znamion-wideodermatoskopia-tsx" */),
  "component---src-pages-gdansk-usuwanie-znamion-pieprzykow-tsx": () => import("./../../../src/pages/gdansk/usuwanie-znamion-pieprzykow.tsx" /* webpackChunkName: "component---src-pages-gdansk-usuwanie-znamion-pieprzykow-tsx" */),
  "component---src-pages-gdynia-dermatoskopowe-badanie-znamion-wideodermatoskopia-tsx": () => import("./../../../src/pages/gdynia/dermatoskopowe-badanie-znamion-wideodermatoskopia.tsx" /* webpackChunkName: "component---src-pages-gdynia-dermatoskopowe-badanie-znamion-wideodermatoskopia-tsx" */),
  "component---src-pages-gdynia-usuwanie-znamion-pieprzykow-tsx": () => import("./../../../src/pages/gdynia/usuwanie-znamion-pieprzykow.tsx" /* webpackChunkName: "component---src-pages-gdynia-usuwanie-znamion-pieprzykow-tsx" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-katowice-dermatoskopowe-badanie-znamion-wideodermatoskopia-tsx": () => import("./../../../src/pages/katowice/dermatoskopowe-badanie-znamion-wideodermatoskopia.tsx" /* webpackChunkName: "component---src-pages-katowice-dermatoskopowe-badanie-znamion-wideodermatoskopia-tsx" */),
  "component---src-pages-katowice-usuwanie-znamion-pieprzykow-tsx": () => import("./../../../src/pages/katowice/usuwanie-znamion-pieprzykow.tsx" /* webpackChunkName: "component---src-pages-katowice-usuwanie-znamion-pieprzykow-tsx" */),
  "component---src-pages-kontakt-index-tsx": () => import("./../../../src/pages/kontakt/index.tsx" /* webpackChunkName: "component---src-pages-kontakt-index-tsx" */),
  "component---src-pages-krakow-dermatoskopowe-badanie-znamion-wideodermatoskopia-tsx": () => import("./../../../src/pages/krakow/dermatoskopowe-badanie-znamion-wideodermatoskopia.tsx" /* webpackChunkName: "component---src-pages-krakow-dermatoskopowe-badanie-znamion-wideodermatoskopia-tsx" */),
  "component---src-pages-krakow-usuwanie-znamion-pieprzykow-tsx": () => import("./../../../src/pages/krakow/usuwanie-znamion-pieprzykow.tsx" /* webpackChunkName: "component---src-pages-krakow-usuwanie-znamion-pieprzykow-tsx" */),
  "component---src-pages-lodz-dermatoskopowe-badanie-znamion-wideodermatoskopia-tsx": () => import("./../../../src/pages/lodz/dermatoskopowe-badanie-znamion-wideodermatoskopia.tsx" /* webpackChunkName: "component---src-pages-lodz-dermatoskopowe-badanie-znamion-wideodermatoskopia-tsx" */),
  "component---src-pages-lodz-usuwanie-znamion-pieprzykow-tsx": () => import("./../../../src/pages/lodz/usuwanie-znamion-pieprzykow.tsx" /* webpackChunkName: "component---src-pages-lodz-usuwanie-znamion-pieprzykow-tsx" */),
  "component---src-pages-nie-przekonuj-sie-na-wlasnej-skorze-index-tsx": () => import("./../../../src/pages/nie-przekonuj-sie-na-wlasnej-skorze/index.tsx" /* webpackChunkName: "component---src-pages-nie-przekonuj-sie-na-wlasnej-skorze-index-tsx" */),
  "component---src-pages-nie-przekonuj-sie-na-wlasnej-skorze-o-kampanii-tsx": () => import("./../../../src/pages/nie-przekonuj-sie-na-wlasnej-skorze/o-kampanii.tsx" /* webpackChunkName: "component---src-pages-nie-przekonuj-sie-na-wlasnej-skorze-o-kampanii-tsx" */),
  "component---src-pages-o-nas-index-tsx": () => import("./../../../src/pages/o-nas/index.tsx" /* webpackChunkName: "component---src-pages-o-nas-index-tsx" */),
  "component---src-pages-poznan-dermatoskopowe-badanie-znamion-wideodermatoskopia-tsx": () => import("./../../../src/pages/poznan/dermatoskopowe-badanie-znamion-wideodermatoskopia.tsx" /* webpackChunkName: "component---src-pages-poznan-dermatoskopowe-badanie-znamion-wideodermatoskopia-tsx" */),
  "component---src-pages-poznan-konsultacje-onkologiczne-leczenie-czerniaka-tsx": () => import("./../../../src/pages/poznan/konsultacje-onkologiczne-leczenie-czerniaka.tsx" /* webpackChunkName: "component---src-pages-poznan-konsultacje-onkologiczne-leczenie-czerniaka-tsx" */),
  "component---src-pages-poznan-laserowe-usuwanie-znamion-pieprzykow-wlokniakow-brodawek-kurzajek-tsx": () => import("./../../../src/pages/poznan/laserowe-usuwanie-znamion-pieprzykow-wlokniakow-brodawek-kurzajek.tsx" /* webpackChunkName: "component---src-pages-poznan-laserowe-usuwanie-znamion-pieprzykow-wlokniakow-brodawek-kurzajek-tsx" */),
  "component---src-pages-poznan-usuwanie-znamion-pieprzykow-tsx": () => import("./../../../src/pages/poznan/usuwanie-znamion-pieprzykow.tsx" /* webpackChunkName: "component---src-pages-poznan-usuwanie-znamion-pieprzykow-tsx" */),
  "component---src-pages-szczecin-dermatoskopowe-badanie-znamion-wideodermatoskopia-tsx": () => import("./../../../src/pages/szczecin/dermatoskopowe-badanie-znamion-wideodermatoskopia.tsx" /* webpackChunkName: "component---src-pages-szczecin-dermatoskopowe-badanie-znamion-wideodermatoskopia-tsx" */),
  "component---src-pages-szczecin-laserowe-usuwanie-znamion-pieprzykow-wlokniakow-brodawek-kurzajek-tsx": () => import("./../../../src/pages/szczecin/laserowe-usuwanie-znamion-pieprzykow-wlokniakow-brodawek-kurzajek.tsx" /* webpackChunkName: "component---src-pages-szczecin-laserowe-usuwanie-znamion-pieprzykow-wlokniakow-brodawek-kurzajek-tsx" */),
  "component---src-pages-szczecin-usuwanie-znamion-pieprzykow-tsx": () => import("./../../../src/pages/szczecin/usuwanie-znamion-pieprzykow.tsx" /* webpackChunkName: "component---src-pages-szczecin-usuwanie-znamion-pieprzykow-tsx" */),
  "component---src-pages-tags-index-tsx": () => import("./../../../src/pages/tags/index.tsx" /* webpackChunkName: "component---src-pages-tags-index-tsx" */),
  "component---src-pages-warszawa-dermatoskopowe-badanie-znamion-wideodermatoskopia-tsx": () => import("./../../../src/pages/warszawa/dermatoskopowe-badanie-znamion-wideodermatoskopia.tsx" /* webpackChunkName: "component---src-pages-warszawa-dermatoskopowe-badanie-znamion-wideodermatoskopia-tsx" */),
  "component---src-pages-warszawa-laserowe-usuwanie-znamion-pieprzykow-wlokniakow-brodawek-kurzajek-tsx": () => import("./../../../src/pages/warszawa/laserowe-usuwanie-znamion-pieprzykow-wlokniakow-brodawek-kurzajek.tsx" /* webpackChunkName: "component---src-pages-warszawa-laserowe-usuwanie-znamion-pieprzykow-wlokniakow-brodawek-kurzajek-tsx" */),
  "component---src-pages-warszawa-usuwanie-znamion-pieprzykow-tsx": () => import("./../../../src/pages/warszawa/usuwanie-znamion-pieprzykow.tsx" /* webpackChunkName: "component---src-pages-warszawa-usuwanie-znamion-pieprzykow-tsx" */),
  "component---src-pages-wroclaw-dermatoskopowe-badanie-znamion-wideodermatoskopia-tsx": () => import("./../../../src/pages/wroclaw/dermatoskopowe-badanie-znamion-wideodermatoskopia.tsx" /* webpackChunkName: "component---src-pages-wroclaw-dermatoskopowe-badanie-znamion-wideodermatoskopia-tsx" */),
  "component---src-pages-wroclaw-laserowe-usuwanie-znamion-pieprzykow-wlokniakow-brodawek-kurzajek-tsx": () => import("./../../../src/pages/wroclaw/laserowe-usuwanie-znamion-pieprzykow-wlokniakow-brodawek-kurzajek.tsx" /* webpackChunkName: "component---src-pages-wroclaw-laserowe-usuwanie-znamion-pieprzykow-wlokniakow-brodawek-kurzajek-tsx" */),
  "component---src-pages-wroclaw-usuwanie-znamion-pieprzykow-tsx": () => import("./../../../src/pages/wroclaw/usuwanie-znamion-pieprzykow.tsx" /* webpackChunkName: "component---src-pages-wroclaw-usuwanie-znamion-pieprzykow-tsx" */),
  "component---src-pages-zywiec-dermatoskopowe-badanie-znamion-wideodermatoskopia-tsx": () => import("./../../../src/pages/zywiec/dermatoskopowe-badanie-znamion-wideodermatoskopia.tsx" /* webpackChunkName: "component---src-pages-zywiec-dermatoskopowe-badanie-znamion-wideodermatoskopia-tsx" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

