import 'lazysizes'

export const onClientEntry = () => {
  window.onload = () => {
    var e;
    // window.LOAD_GTM_IN_THE_IFRAME = true;
    if (!document.getElementById("apptnow-js-sdk")) {
      e = document.createElement("script");
      e.id = "apptnow-js-sdk";
      e.async = !0;
      e.src = "https://static.appt-now.pl/script.js"
      document.head.appendChild(e)
    }
  }
};